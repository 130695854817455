import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class DataTable3 extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef()
  formRefKeterangan = React.createRef()
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    var columns = [];
    // let pk = "";
    props.models.map((item, idx) => {
      if (item.isPk != undefined && item.isPk == true) {
        //cell tombol aksi
        // if (columns.length == 0) {
        this.pk = item.name;
        columns = [
          {
            title: "AKSI",
            width: 120,
            fixed: "left",
            dataIndex: item.name,
            key: "aksi",
            align: 'center',
            render: (value, row, index) => {
              return (
                <div>
                  {row.isTambah != undefined ? ( //form insert is open
                    <>
                      <Form.Item>
                        <Space>

                          <Button
                            htmlType="submit"
                            type="primary"
                            title="simpan"
                          >
                            <SaveOutlined />
                          </Button>

                          <Button
                            htmlType="button"
                            type="primary"
                            danger
                            title="batalkan"
                          >
                            <CloseSquareOutlined />
                          </Button>

                        </Space>
                      </Form.Item>
                    </>
                  ) : row[item.name] == this.state.editingKey ? (
                    <>
                      <Form.Item>
                        <Space>

                          <Button
                            htmlType="submit"
                            type="primary"
                            title="simpan"
                          >
                            <SaveOutlined />
                          </Button>

                          <Button
                            htmlType="button"
                            type="primary"
                            danger
                            title="batalkan"
                          >
                            <CloseSquareOutlined />
                          </Button>

                        </Space>
                      </Form.Item>
                    </>
                  ) : (
                    <Space>
                      <Button
                        type={"text"}
                        // primary
                        disabled={this.state.editingKey !== ""}
                        onClick={() => this.showEdit(row)}
                      >
                        <EditOutlined />
                      </Button>
                    </Space>
                  )}
                </div>
              );
            },
          },
          ...columns,
        ];
        // }
      }
      if (item.isTable) {
        if (item.type != "label" && this.focusComponent == "") {
          this.focusComponent = item.name;
        }
        //other component cell
        columns.push({
          title: item.label,
          width: 150,
          editable: true,
          dataIndex: item.name,
          key: item.name,
          align:
            item.tableTipe != undefined && item.tableTipe == "download_link"
              ? "center"
              : "left",
          render: (value, row, index) => {
            // console.log(row[item.name]);
            let content;
            if (row.isTambah) {
              content = value;
            } else if (row[this.pk] == this.state.editingKey) {
              if (item.type == "text") {
                content = (
                  <Form.Item
                    name={item.name}
                    rules={
                      item.validation.update != undefined
                        ? item.validation.update
                        : item.validation.insert
                    }
                  >
                    <Input id={item.name} />
                  </Form.Item>
                );
              } else if (item.type == "file") {
                content = (
                  <Form.Item
                    name={item.name}
                    rules={
                      item.validation.update != undefined
                        ? item.validation.update
                        : item.validation.insert
                    }
                  >
                    <Input
                      id={item.name}
                      type="file"
                      onChange={(e) => this.setFile(e, item.name)}
                    />
                  </Form.Item>
                );
              } else if (item.type == "select") {
                content = (
                  <Form.Item
                    name={item.name} rules={item.validation.insert}
                    rules={
                      item.validation.update != undefined
                        ? item.validation.update
                        : item.validation.insert
                    }
                  >
                    {this.generateSelectAsync(item.name, item.references.apiUrl, item.references.label, item.references.value)}
                  </Form.Item>
                );
              } else if (item.type == "number") {
                content = (
                  <Form.Item
                    name={item.name}
                    rules={
                      item.validation.update != undefined
                        ? item.validation.update
                        : item.validation.insert
                    }
                  >
                    <InputNumber id={item.name} />
                  </Form.Item>
                );
              } else if (item.type == "textarea") {
                content = (
                  <>
                    <Form.Item
                      name={item.name}
                      style={{ display: "none" }}
                    >
                      <Input.TextArea id={item.name} />
                    </Form.Item>
                    <Form.Item>
                      <div
                        id={item.name + "_preview"}
                        className="keterangan"
                        onClick={() => {
                          this.setState({
                            openKeterangan: true,
                            modalItemLabel: item.label,
                            modalItemName: item.name,
                            modalValidation: item.validation.update != undefined ? item.validation.update : item.validation.insert,
                          }, () => {
                            if (document.getElementById(item.name).value != "") {
                              this.formRefKeterangan.current.setFieldsValue({
                                [item.name]: document.getElementById(item.name).value
                              });
                            } else {
                              this.formRefKeterangan.current.setFieldsValue({
                                [item.name]: undefined
                              });
                            }
                            // if(value != null && value != ""){
                            //   this.formRefKeterangan.current.setFieldsValue({
                            //     [item.name]: value
                            //   });
                            // }else{
                            //   if(document.getElementById(item.name).value != ""){
                            //     this.formRefKeterangan.current.setFieldsValue({
                            //       [item.name]: document.getElementById(item.name).value
                            //     });
                            //   }else{
                            //     this.formRefKeterangan.current.setFieldsValue({
                            //       [item.name]: undefined
                            //     });
                            //   }
                            // }
                          })
                        }}
                      >
                        {value == null || value == "" ? "input keterangan" : value}
                      </div>
                    </Form.Item>

                  </>
                );
              } else {
                content = (
                  <Form.Item>
                    {value}
                  </Form.Item>
                )
              }
            } else {
              if (item.tableTipe != undefined) {
                if (item.tableTipe == "download_link") {
                  content = (
                    <a href={value} target="_blank">
                      <Button type="link">
                        <DownloadOutlined />
                      </Button>
                    </a>
                  );
                } else if (item.tableTipe == "deskripsi") {
                  content = (
                    <div
                      className="keterangan"
                      onClick={() => {
                        this.setState({
                          openKeteranganDetail: true,
                          dataKeteranganDetail: value
                        })
                      }}
                    >
                      {value}
                    </div>
                  )
                }
              } else if (item.tableValue != undefined) {
                content = row[item.tableValue]
              } else {
                content = value;
              }
            }
            return content;
          },
        });
      }
    });
    // console.log(columns);
    this.state = {
      errTitle: "",
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 10, current: 1, total: 0 },
      searchText: "",
      dataSource: [],
      prevSource: [],
      columns: columns,
      editingKey: "",
      tambah: false,
      openKeterangan: false,
      modalItemLabel: "",
      modalItemName: "",
      modalValidation: [],
      openKeteranganDetail: false,
      dataKeteranganDetail: "",
      yearItems: [],
      tahunDipilih: new Date().getFullYear(),

      aksi: "tambah",
      method: "post",
      idLama: "",
    };
  }

  async getMasterList(stateName, url, isNext = false) {
    this.setState({
      ["loading" + stateName]: !isNext,
      ["loadingNext" + stateName]: isNext,
      ["loadingSearch" + stateName]: isNext,
    });
    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state["data" + stateName] == undefined || this.state["data" + stateName] == null) {
              newState = data;
            } else {
              if (data.previous == null) {//first data
                newState = data;
              } else {
                const oldState = this.state["data" + stateName];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState({
              ["data" + stateName]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              ["data" + stateName]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                ["data" + stateName]: data,
              });
            } else {
              this.setState({
                ["data" + stateName]: [data],
              });
            }
          }
        } else {
          console.log(data)
        }
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      });
  }

  generateYears = () => {
    var start = 2020;
    var end = new Date().getFullYear() + 3;
    var items = [];
    for (var i = start; i <= end; i++) {
      items = [...items, {
        value: i,
        label: i,
      }]
    }
    this.setState({
      yearItems: items
    }, () => {
      // console.log('year', this.state.yearItems)
      this.formRef.current.setFieldsValue({
        tahun: new Date().getFullYear()
      })
    });
  };

  generateSelectAsync = (stateName, url, label, value) => {
    return this.state["loading" + stateName] ? (
      <Skeleton active paragraph={false} />
    ) : (
      <Select
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={true}
        id={stateName}
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        allowClear
        onSearch={(val) => {
          let tempUrl = "";
          if (url.includes("?")) {
            tempUrl = url + "&search=" + val;
          } else {
            tempUrl = url + "?search=" + val;
          }
          this.getMasterList(stateName, tempUrl, false)
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            if (
              this.state["data" + stateName].next != undefined &&
              this.state["data" + stateName].next != null
            ) {
              this.getMasterList(stateName, this.state["data" + stateName].next, true)
            }
          }
        }}
      >
        {this.state["loadingSearch" + stateName] &&
          <Skeleton active paragraph={false} />
        }
        {this.state["data" + stateName] != undefined && this.state["data" + stateName] != null &&
          this.state["data" + stateName].results.map((item) => (
            <Select.Option value={item[value]}>{item[label]}</Select.Option>
          ))}
        {this.state["loadingNext" + stateName] &&
          <Skeleton active paragraph={false} />
        }
      </Select>
    );
  };

  setFile(e, name) {
    this.setState(
      {
        [name + "_file"]: e.target.files[0],
      },
      () => {
        // console.log(this.state[name+"_file"])
      }
    );
  }

  showInsert() {
    // this.formRef.current.resetFields();

    // let tempData = this.state.dataSource;
    // this.setState({
    //   prevSource: tempData,
    //   tambah: true,
    // });
    // let tempForm = {
    //   isTambah: true,
    // };
    // let focusName = "";
    // this.props.models.map((item, idx) => {
    //   if (item.isForm) {
    //     if (item.type == "text") {
    //       // console.log("text");
    //       tempForm[item.name] = (
    //         <Form.Item name={item.name} rules={item.validation.insert}>
    //           <Input id={item.name} />
    //         </Form.Item>
    //       );
    //     } else if (item.type == "file") {
    //       // console.log("file");
    //       tempForm[item.name] = (
    //         <Form.Item name={item.name} rules={item.validation.insert}>
    //           <Input
    //             id={item.name}
    //             type="file"
    //             onChange={(e) => this.setFile(e, item.name)}
    //             // value={""}
    //             defaultValue={""}
    //           />
    //         </Form.Item>
    //       );
    //     } else if (item.type == "number") {
    //       // console.log("text");
    //       tempForm[item.name] = (
    //         <Form.Item name={item.name} rules={item.validation.insert}>
    //           <InputNumber id={item.name} />
    //         </Form.Item>
    //       );
    //     } else if (item.type == "select") {
    //       tempForm[item.name] = (
    //         <Form.Item name={item.name} rules={item.validation.insert}>
    //           {this.generateSelectAsync(item.name, item.references.apiUrl, item.references.label, item.references.value)}
    //         </Form.Item>
    //       );
    //     } else {
    //       //console.log("else");
    //       tempForm[item.name] = (
    //         <Form.Item name={item.name} rules={item.validation.insert}>
    //           <Input id={item.name} />
    //         </Form.Item>
    //       );
    //     }
    //   }
    //   // if(idx == this.props.models.length -1){

    //   // }
    // });
    // tempData = [tempForm, ...tempData];
    // this.setState(
    //   {
    //     dataSource: tempData,
    //   },
    //   () => {
    //     //console.log("fname", focusName);
    //     // this.formRef.current[focusName].focus();
    //     // this.formRef.current.resetFields();
    //     document.getElementById(this.focusComponent).focus();
    //   }
    // );
    //console.log(tempData);
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    // console.log('didmount', this.props)
    this.generateYears();
    this.getData();
    this.props.models.map((item, idx) => {
      if (item.references != undefined) {
        this.getMasterList(item.name, item.references.apiUrl);
      }
    })
  }

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true, errTitle: "", errMsg: "" });

    let baseUrl = this.props.configClass.apiUrl.data;
    let url = "";
    if (baseUrl.includes("?")) {
      url =
        baseUrl + "&tahun=" + this.state.tahunDipilih + "&limit=" + limit + "&offset=" + offset + "&search=" + search;
    } else {
      url =
        baseUrl + "?limit=" + limit + "&tahun=" + this.state.tahunDipilih + "&offset=" + offset + "&search=" + search;
    }

    this.props
      .ajaxViewHandler("get", url)
      .then((results) => {
        // setTimeout(() => {
        const res = this.props.responseMsg;
        if (this.props.isSuccess) {
          let pagination = { ...this.state.pagination };
          pagination.total = res.count;
          pagination.current = offset + 1;
          this.setState({
            loadingData: false,
            dataSource: res.results,
            pagination,
          });
        } else {
          const errTitle = "error get data code 1";
          this.setState({
            loadingData: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(res),
          });
        }
        // }, 500);
      })
      .catch((response) => {
        const errTitle = "error get data code 2";
        this.setState({
          loadingData: false,
          errTitle: errTitle,
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  showDeleteConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapus(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  resetStatePost() {
    this.setState({
      aksi: "tambah",
      method: "post",
      idLama: "",
      tambah: false,
      editingKey: "",
    });
  }

  async postData(datas) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        this.state.method,
        this.props.configClass.apiUrl.preview + this.state.idLama,
        datas,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  showActiveConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Activate?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin mengaktivate data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesActivate(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler("delete", this.props.configClass.apiUrl.hapus + id + "/")
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menghapus.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal menghapus",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  prosesActivate(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler(
        "get",
        this.props.configClass.apiUrl.data + "active/" + id + "/"
      )
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengactivate data.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal mengactivate data",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmit = (values) => {
    // var datas = {};
    // for (var key in values) {
    //   if (values[key] == undefined) {
    //     datas = { ...datas, [key]: "" };
    //   } else {
    //     // datas.append(key, values[key].toString());
    //     datas = { ...datas, [key]: values[key].toString() };
    //   }
    // }
    // this.postData(datas);
    var params = new FormData();
    this.props.models.map((item, idx) => {
      if (item.isForm) {
        if (item.type == "file") {
          if (this.state[item.name + "_file"] != undefined) {
            params.append(
              item.name,
              this.state[item.name + "_file"],
              this.state[item.name + "_file"].name
            );
          } else {
            params.append(item.name, "");
          }
        } else if (item.isTable == false) {
          if (values[item.name] == undefined) {
            params.append(item.name, item.defaultValue);
          } else {
            params.append(item.name, values[item.name]);
          }
        } else {
          params.append(item.name, values[item.name]);
        }
      }
    });
    // setTimeout(() =>{
    //   this.postData(params)
    // }, 1000)
    this.postData(params);
  };

  handleSubmitKeterangan = (values) => {
    for (var key in values) {
      // console.log("document.getElementById("+key+").value = "+values[key]+")")
      document.getElementById(key + "_preview").innerHTML = values[key];
      // document.getElementById(key).value = values[key];
      // document.querySelector("#"+key).innerText  = values[key];
      this.formRef.current.setFieldsValue({
        [key]: values[key]
      });
    }
    this.setState({
      openKeterangan: false
    })
  };

  render() {
    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.configClass.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                        {/*
                        <Button
                          disabled={
                            this.state.editingKey != "" || this.state.tambah
                              ? true
                              : false
                          }
                          loading={this.state.loadingData}
                          size="small"
                          type="primary"
                          onClick={() => {
                            this.showInsert();
                          }}
                        >
                          <PlusOutlined /> upload
                        </Button>
                        */}
                      </Space>
                    </Col>
                  </Row>
                }
              >
                {this.state.errMsg != "" &&
                  <Alert
                    message={this.state.errMsg}
                    type="error"
                    showIcon />
                }
                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                >
                  <Table
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    loading={this.state.loadingData}
                    scroll={{ x: "max-content", y: 450 }}
                    size="small"
                    pagination={this.state.pagination}
                    rowKey={this.pk}
                    onChange={this.handleTableChange}
                    title={() => (
                      <Row>
                        <Col lg={8} sm={24} xs={24}>
                          <span className="konten-judul">
                            Total {this.state.pagination.total} record
                          </span>
                        </Col>
                        <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name="tahun"
                                rules={[
                                  {
                                    required: true,
                                    message: " harus dipilih",
                                  },
                                ]}
                              >
                                <Select
                                  size={"small"}
                                  options={this.state.yearItems}
                                  showSearch
                                  placeholder="pilih"
                                  optionFilterProp="children"
                                  onChange={(val) => {
                                    this.setState({
                                      tahunDipilih: val
                                    }, () => {
                                      this.getData()
                                    })
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={18}>
                              <Form.Item>
                                <Input.Search
                                  size="small"
                                  placeholder="ketikkan pencarian"
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  onSearch={(value) => {
                                    this.setState(
                                      {
                                        searchText: value,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  />
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          title={
            <div style={{ textAlign: "center" }}>
              {"KETERANGAN"}
            </div>
          }
          centered
          open={this.state.openKeterangan}
          onCancel={() => this.setState({ openKeterangan: false })}
          width={"50%"}
          footer={[]}
        >
          <Form
            name="fketerangan"
            onFinish={this.handleSubmitKeterangan}
            autoComplete="off"
            ref={this.formRefKeterangan}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Form.Item
              className="form-modal"
              label={this.state.modalItemLabel}
              name={this.state.modalItemName}
              rules={this.state.modalValidation}
            // id={this.state.modalItemName+"_modal"}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit">
                  <SaveOutlined />
                  Simpan
                </Button>
                <Button
                  type="primary"
                  danger
                  htmlType="button"
                  onClick={() => {
                    this.setState({
                      openKeterangan: false,
                    });
                  }}
                >
                  <CloseSquareOutlined />
                  Batal
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={
            <div style={{ textAlign: "center" }}>
              {"KETERANGAN"}
            </div>
          }
          centered
          open={this.state.openKeteranganDetail}
          onCancel={() => this.setState({ openKeteranganDetail: false })}
          width={"50%"}
          footer={[]}
        >
          <div>
            {this.state.dataKeteranganDetail}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(DataTable3));
